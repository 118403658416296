import styled from 'styled-components'
import { breakpoints } from '@/styles/media'
import { Image } from '@/elements/Image/NextImage'
import { useRouter } from 'next/router'
import { ZipForm, ZipWrapper } from '@/components/Marketing/ZipCheckForm'
import { type SplitHeroV2Data } from '@/components/CMS/types'
import { useUtmUrl } from '@/hooks/useUtmUrl'
import { Button } from '@shipt/design-system-buttons'
import { CMSContainer } from '@/components/CMS/CMSContainer'
import { ReferenceRenderer } from '@/components/CMS/ReferenceRenderer'
import { AddressWrapper } from '@/components/CMS/components/AddressCheck'
import { CMSErrorHandler } from '@/components/CMS/CMSErrorHandler'
import { SingleEntryForm } from '@/components/CMS/components/SingleEntryForm'
import { spacing } from '@/theme/tokens'
import { CMSFullBleed } from '@/components/CMS/CMSFullBleed'
import { Display, Body } from '@shipt/design-system-typography'
import { useCMSTrackingEvents } from '@/components/CMS/CMSContext'
import { renderElement } from '@/components/CMS/RichTextRenderer'
import { classNames } from '@/utils/classNames'
import { useHandleDialog } from '@/state/Dialog/useHandleDialog'
import { ModalType } from '@/constants/modalTypes'
import { parseCategoryId } from '@/components/CMS/utils/parse'

export const SplitHeroV2 = ({
  content_type_id,
  id,
  data: {
    heading,
    heading_color = '#000000',
    subheading,
    subheading_rich_text,
    subheading_color = '#000000',
    image,
    hide_mobile_image,
    image_placement = 'Desktop: Right / Mobile: Bottom',
    background_color = '#fff',
    call_to_action,
    references,
    single_entry_form = [],
    partner_logo,
    enable_store_selection_drawer,
  },
  className,
}: SplitHeroV2Data & { className?: string }) => {
  const router = useRouter()
  const { openDialog } = useHandleDialog()
  const categoryId = parseCategoryId(call_to_action?.url || '') || 0
  const url = useUtmUrl(call_to_action?.url || '')
  const { trackCMSElementClicked } = useCMSTrackingEvents()

  const handleButtonClick = () => {
    trackCMSElementClicked({
      type: 'button',
      content: call_to_action?.text,
    })
    if (enable_store_selection_drawer) {
      return openDialog(ModalType.PROMOTION_STORES_DRAWER, {
        category_id: categoryId,
      })
    }
    if (url) return router.push(url)
  }

  try {
    return (
      <CMSFullBleed
        $background_color={background_color}
        className={classNames(background_color && 'with-background', className)}
      >
        <Container
          id={id}
          contentTypeId={content_type_id}
          image_placement={image_placement}
        >
          <Split>
            <Content>
              {!!partner_logo?.src && (
                <PartnerLogoWrapper>
                  <Image
                    src={partner_logo.src}
                    alt={partner_logo.alt}
                    style={{ objectFit: 'contain' }}
                    width={partner_logo.width}
                    height={partner_logo.height}
                  />
                </PartnerLogoWrapper>
              )}
              <Heading $heading_color={heading_color} size="sm" align="center">
                {heading}
              </Heading>
              {subheading_rich_text && (
                <Subheading
                  as="div"
                  $subheading_color={subheading_color}
                  align="center"
                >
                  {subheading_rich_text?.elements.map(renderElement)}
                </Subheading>
              )}
              {subheading && !subheading_rich_text && (
                <Subheading $subheading_color={subheading_color} align="center">
                  {subheading}
                </Subheading>
              )}
              <ReferenceRenderer references={references} />
              {call_to_action && !references && (
                <Button concept="accent" onClick={handleButtonClick}>
                  {call_to_action?.text}
                </Button>
              )}
              {single_entry_form?.length > 0 && (
                <SingleEntryForm data={single_entry_form[0]} />
              )}
            </Content>
          </Split>
          <Split $hiddenOnMobile={hide_mobile_image}>
            <Content>
              {image?.src && (
                <HeroImage
                  src={image.src}
                  alt={image.alt}
                  style={{ width: '100%', height: 'auto' }}
                  width={image.width}
                  height={image.height}
                  priority
                />
              )}
            </Content>
          </Split>
        </Container>
      </CMSFullBleed>
    )
  } catch (error) {
    return (
      <CMSErrorHandler error={error} contentTypeId={content_type_id} id={id} />
    )
  }
}

const Container = styled(CMSContainer)<{
  image_placement: string
}>`
  display: flex;
  flex-direction: ${({ image_placement }) =>
    image_placement === 'Desktop: Left / Mobile: Top'
      ? 'column-reverse'
      : 'column'};
  margin: ${spacing('auto')};
  padding-right: ${spacing('xl')};
  padding-left: ${spacing('xl')};
  gap: ${spacing('xxl')};
  border-radius: 8px;
  align-items: center;

  @media ${breakpoints.medium} {
    flex-direction: ${({ image_placement }) =>
      image_placement === 'Desktop: Left / Mobile: Top'
        ? 'row-reverse'
        : 'row'};
    padding-right: 0;
    padding-left: 0;
  }

  @media ${breakpoints.xlarge} {
    gap: 3.5rem;
  }
`

const Split = styled.div<{ $hiddenOnMobile?: boolean }>`
  width: 100%;
  height: 100%;

  @media ${breakpoints.small} {
    display: ${({ $hiddenOnMobile }) => ($hiddenOnMobile ? 'none' : null)};
  }
`

const HeroImage = styled(Image)`
  width: 100%;
  border-radius: 8px;
`

const PartnerLogoWrapper = styled.div`
  max-width: 12rem;
  max-height: 9rem;
  margin: ${spacing(0, 'auto', 'lg')};

  @media ${breakpoints.medium} {
    margin-right: ${spacing(0)};
    margin-bottom: ${spacing('xl')};
    margin-left: ${spacing(0)};
  }
`

const Heading = styled(Display)<{ $heading_color: string | null }>`
  margin-bottom: ${spacing(20)};
  color: ${({ $heading_color }) => $heading_color};

  @media ${breakpoints.medium} {
    text-align: start;
    padding-right: ${spacing(20)};
    font-size: 2.5rem;
  }

  @media ${breakpoints.xlarge} {
    font-size: 3rem;
    padding-bottom: ${spacing('xl')};
    line-height: 3.3rem;
  }
`

const Subheading = styled(Body)<{ $subheading_color: string | null }>`
  margin-bottom: ${spacing('lg')};

  *,
  & {
    color: ${({ $subheading_color }) => $subheading_color};
  }

  @media ${breakpoints.medium} {
    text-align: start;
    padding-right: ${spacing(20)};
  }

  b {
    font-weight: 600;
  }

  @media ${breakpoints.xlarge} {
    padding-bottom: ${spacing('xl')};
  }
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${breakpoints.medium} {
    align-items: flex-start;
  }

  ${ZipWrapper} {
    margin-bottom: ${spacing(0)};
    margin-top: ${spacing('sm')};
  }

  ${ZipForm} {
    justify-content: flex-start;
  }

  ${AddressWrapper} {
    width: 100%;
  }
`
